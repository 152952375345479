import React from "react";

const Star = ({xpos, ypos, width, height, delay, duration}) => {

    const containerStyle = {
        position: 'absolute',
        top: ypos,
        left: xpos,
        width: width,
        height: height,              
    };

    const starStyle = {       
        borderRadius: '50%',
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
        animationDelay: `${delay}s`,
        animationDuration: `${duration}s`,
    };
    
    return (
        <div style={containerStyle} className='star-container'>
            <div className='star-animation' style={starStyle} />
        </div>
    );
}

export default Star;