import React, { useEffect, useState } from "react";
import Fade from "@mui/material/Fade";
import StarSky from "./StarSky.jsx";

const ImageInOutFader = ({ isLoading, fadeInImg, fadeOutImg }) => {
  
  const maxTime = 20000;
  const minTime = 10000;
  const minDuration = 3000;
  const maxDuration = 6000;

  let nextMoveInterval = 0;

  const doAnimateRobot = true;

  useEffect(() => {
    console.log("ImageInOutFader: ", isLoading, fadeInImg, fadeOutImg);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    nextMoveInterval = getRandomInt(minTime, maxTime);
    console.log('Timer: ', nextMoveInterval);
    
    if(doAnimateRobot) {
      const timer = setInterval(() => {      
        nextMoveInterval = getRandomInt(minTime, maxTime);
        console.log('Timer: ', nextMoveInterval); 
        setAnimationDuration(`${getRandomInt(minDuration, maxDuration)}ms`);
      }, nextMoveInterval);    

      return () => clearInterval(timer);
    }
  }, [isLoading, fadeInImg, fadeOutImg]);

  const [animationDuration, setAnimationDuration] = useState("6s");

  const getRandomInt = (min, max) => Math.random() * (max - min) + min;

  let moveStyle = {
    animationName: "moveRobot",
    animationDuration: animationDuration,
    animationDelay: "3s",
  };

  return (
    <>
      <StarSky numberOfStarsFactor={150} maxSize="4" />
      <div className="image-transform-area flex-center-container">
        <div className="image-fade flex-center-container">
          {isLoading && (
            <img
              src={fadeInImg.src}
              alt={fadeInImg.name}
              className="image-transform"
            />
          )}
          <Fade in={!isLoading} timeout={3000}>
            {!isLoading ? 
              <img
                id={moveStyle.animationDuration}
                key={moveStyle.animationDuration}
                src={fadeInImg.src}
                alt={fadeInImg.name}
                className="image-transform"
                style={moveStyle}
              />
             : <div></div>
            }
          </Fade>
          <Fade in={isLoading} timeout={3000}>
            {!isLoading ? (
              <img
                src={fadeOutImg.src}
                alt={fadeOutImg.name}
                className="image-transform"
              />
            ) : <div></div>
            }
          </Fade>
        </div>

        <div className="image-name">
          {isLoading && <span>{fadeInImg.name}</span>}
          {!isLoading && (
            <Fade in={!isLoading} timeout={3000}>
              <span>{fadeInImg.name}</span>
            </Fade>
          )}
        </div>
      </div>

      {/* <div style={{ position: "absolute", bottom: "1em" }}>
        <button onClick={() => setAnimationDuration("3s")}>3s</button>
        <button onClick={() => setAnimationDuration("6s")}>6s</button>
        <button onClick={() => setAnimationDuration("9s")}>9s</button>
      </div> */}
    </>
  );
};

export default ImageInOutFader;