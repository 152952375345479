/* eslint-disable no-unused-vars */

import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import ImageInOutFader from './components/ImageInOutFader.jsx';

function App() {

  const [data, setData] = useState();  
  const [isLoading, setIsLoading] = useState(false);  

  const [imgState, setImgState] = useState({img0: {name: "", url: ""}, img1: {name: "", url: ""}});
  const [imgData, setImgData] = useState({img0: {src: null, name: null}, img1: {src: null, name: null}});
  
  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      fetch(imgState.img0.url).then(response => response.blob()),
    ]).then(([blob0]) => {
      setImgData((prevState) => ({img0: {src: URL.createObjectURL(blob0), name: imgState.img0?.name}, img1:  {...prevState.img0}}));
      setIsLoading(false);
    });
  }, [imgState.img0]);

  useEffect(() => {      
    console.log("start!");
    update();
    setTimeout(() => {      
      setInterval(update, 360000);
    }, 360000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    console.log("img0: ", imgState.img0.name);
    console.log("img1: ", imgState.img1.name);
  }, [imgState]); 

  const update = () => {    
    //let formattedDate = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
    let formattedDate = new Date().toLocaleString('sv-SE');
    console.log('updating:', formattedDate);
    fetchData(true);
  };

  const fetchData = () => {    
    fetch(`https://random-data-api.com/api/v2/users?size=1&response_type=json`)
      .then((response) => {
        console.log('fetch-response: ', response);
        response.json()
          .then((respData) => {
            setData(respData);
            setImgState((prevState) => ({ img0: {url: respData?.avatar, name: `${respData.first_name} ${respData.last_name}`}, img1: {...prevState.img0} }));
          })
          .catch((error) => {
            console.error('Fel vid parse av fetch-data: ', error);
          });
      })
  };  

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" width={300} height={300} />        
        <div className='preview-img-area'>
            <span>
              <img src={imgData.img0.src} width={150} alt={imgData.img0.name} />
              <div>{imgState.img0.name}</div>
            </span>
            <span>
              <img src={imgData.img1.src} width={150} alt="img1" />  
              <div>{imgState.img1.name}</div>
            </span>
        </div>        
      </header>
      <main className='App-main flex-center-container'>        
        <ImageInOutFader isLoading={isLoading} fadeInImg={imgData.img0} fadeOutImg={imgData.img1} />        
        <button className='button' onClick={ update }>{isLoading ? 'fetching...' : 'Fetch'}</button>
      </main>
      <footer className='App-footer'>
      </footer>
    </div>
  );
}

export default App;
