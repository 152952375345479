import React, { useEffect, useRef, useState } from 'react';
import Star from './Star';

const StarSky = ({numberOfStarsFactor, maxSize}) => {

    const starsRef = useRef(null);
    const currentWindowSize = useRef(window.innerWidth * window.innerHeight);

    const [newWindowSize, setNewWindowSize] = useState(window.innerWidth * window.innerHeight);
    
    useEffect(() => {
        currentWindowSize.current = window.innerWidth * window.innerHeight;
        starsRef.current = createStars();
        const handleResize = () => setNewWindowSize(window.innerWidth * window.innerHeight);
        window.addEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
      const starsDiff = ((newWindowSize - currentWindowSize.current)  / 11000) * (numberOfStarsFactor / 100);      
      if(Math.abs(starsDiff) >= 1){
        adjustStars(starsDiff);
        currentWindowSize.current = newWindowSize;
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newWindowSize, numberOfStarsFactor]);

    const newStar = () =>{
        const x = Math.random() * 100 + 'vw';
        const y = Math.random() * 50 + 'vh';
        const animDelay = Math.random() * numStars();
        const animDuration = Math.random() * 10 + 8; // 8-18s
        
        const minSize = maxSize - 3;
        const size = Math.random() * (maxSize - minSize) + minSize;
        
        return <Star xpos={x} ypos={y} width={size+'px'} height={size+'px'} delay={animDelay} duration={animDuration} />;
    }
  
    const createStars = () => {
        const stars = Array.from({ length: numStars() }).map((_, i) => {
            return newStar();
          });
        return stars
    }
    
    const adjustStars = (deltaStars) => {
      const intStars = parseInt(deltaStars); 
      console.log("Delta stars: ", intStars);     
      if(intStars > 0) {       
        for(let i = 0; i < intStars; i++){
          starsRef.current.push(newStar());
        }        
      }
      else
        starsRef.current.splice(starsRef.current.length + intStars, -intStars);

      console.log("Adjusted stars: ", starsRef.current.length);      
    }

    let numStars = () => {
      let nbr = (currentWindowSize.current / 11000) * (numberOfStarsFactor / 100);
      console.log("numStars: ", nbr);
      return nbr;
    }

  return <div>{starsRef.current}</div>;
}

export default StarSky;